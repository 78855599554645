import axios from '@/axios'

export default {
  async getAmenities() {
    try {
      const { data:res } = await axios.get(`room-amenities`);
      if (res.response_code === 0) {
        return res.data;
      }
    } catch (err) {
      this.$vue.$catchErrorResponse(err)
      return []
    }
  },
  async putAmenities({}, {hotelId, roomId, payload}) {
    try {
      await axios.put(`hotels/${hotelId}/rooms/${roomId}/amenities`, payload);
    } catch (err) {
      this.$vue.$catchErrorResponse(err)
      return []
    }
  },
}
