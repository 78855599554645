import Vue from "vue";
import VxTooltip from "./layouts/components/vx-tooltip/VxTooltip.vue";
import VxCard from "./components/vx-card/VxCard.vue";
import VxList from "./components/vx-list/VxList.vue";
import VxBreadcrumb from "./layouts/components/VxBreadcrumb.vue";
import FeatherIcon from "./components/FeatherIcon.vue";
import VxInputGroup from "./components/vx-input-group/VxInputGroup.vue";

Vue.component(VxTooltip.name, VxTooltip);
Vue.component(VxCard.name, VxCard);
Vue.component(VxList.name, VxList);
Vue.component(VxBreadcrumb.name, VxBreadcrumb);
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component(VxInputGroup.name, VxInputGroup);

// v-select component
import vSelect from "vue-select";

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement =>
      createElement("feather-icon", {
        props: {
          icon: "XIcon",
          svgClasses: "w-4 h-4 mt-1"
        }
      })
  },
  OpenIndicator: {
    render: createElement =>
      createElement("feather-icon", {
        props: {
          icon: "ChevronDownIcon",
          svgClasses: "w-5 h-5"
        }
      })
  }
});

Vue.component(vSelect);

Vue.prototype.$serverStorage = 'http://localhost:8000/storage/'

Vue.prototype.$catchErrorResponse = async function(err) {
  // if error is unauthorized
  console.log(err)
  if (err.status === 401) {
    localStorage.setItem('redirectUrl', this.$router.currentRoute.path);
    await this.$store.dispatch('auth/logout');
    this.$vs.notify({
      title: "Unauthorized",
      text: `${err.data.msg_status}, please to re-login`,
      iconPack: "feather",
      icon: "icon-alert-circle",
      color: "danger",
      time: 5000
    });
  } else if (err.status === 422) {
    this.$setErrorsFromResponse(err.data.message);
    setTimeout(() => {
      this.$validator.errors.clear();
    }, 2000);
  } else if (err.status === 403) {
    this.$router.push({ name: 'page-error-403' })
  } else {
    let text = `Server Error for some reason, please contact Admin of Reservancy for more detail`;
    if (err.data.message) {
      text = err.data.message;
    }
    this.$vs.notify({
      title: "Error",
      text,
      iconPack: "feather",
      icon: "icon-alert-circle",
      color: "danger",
      time: 3000
    });
  }
};

// check valid description for room detail and brand
Vue.prototype.$isValidDescription = function (str) {
   // check if description contains a website link
   const regExp = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/)

   if (regExp.test(str)) {
     this.$vs.notify({
         title: "Something went wrong",
         text: 'Your description contains a website url, please remove it !',
         iconPack: "feather",
         icon: "icon-check-circle",
         color: "warning"
       });

     return false
   }

   return true
}

export function debounce (fn, delay) {
  let timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    const args = arguments
    const that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}
