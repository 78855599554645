import axios from 'axios'

// const baseURL = 'https://103.102.152.200/api/v1'
export const baseURL = 'https://api.reservancy.com/v1'

export function getToken() {
  return localStorage.getItem('token') ?? sessionStorage.getItem('token')
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken') ?? sessionStorage.getItem('refreshToken')
}

export const authHeaders = () => ({
  'Authorization': `Bearer ${getToken()}`
});

axios.defaults.baseURL = `${baseURL}`

axios.defaults.headers.common[
  'Authorization'
] = `Bearer ${getToken()}`

axios.defaults.headers.common[
  'Accept'
] = 'application/json'

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  // Network error
  if (!error.response) {
    error = {
      status: 500,
      data: {
        message: 'Server is not running'
      },
      statusText: 'Internal Server Error'
    }

  } else {
    error = error.response
  }

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error)
})

export default axios
