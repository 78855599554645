import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)


import ElementUI from 'element-ui';
import './assets/scss/element-variables.scss';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
locale.use(lang);
Vue.use(ElementUI);

// axios
import axios from './axios.js'
Vue.prototype.$http = axios

// API Calls
import './http/requests'

// mock
import './fake-db/index.js'

// Theme Configurations
import '../themeConfig.js'

// ACL
import acl from './acl/acl'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// i18n
import i18n from './i18n/i18n'


// Vuexy Admin Filters
import './filters/filters'


// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


// Tour
import VueTour from 'vue-tour'
Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')

//  Vue Meta
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)
Vue.prototype.$setErrorsFromResponse = function(errorResponse) {
  // only allow this function to be run if the validator exists
  if(!this.hasOwnProperty('$validator')) {
      return;
  }

  // clear errors
  this.$validator.errors.clear();

  let errorFields = Object.keys(errorResponse);

  // insert laravel errors
  errorFields.map(field => {
      let errorString = errorResponse[field].join(', ');
      this.$validator.errors.add({
          field:field,
          msg: errorString
      });
  });
};



// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'AIzaSyB4DDathvvwuwlwnUu7F4Sow3oU22y5T1Y',
    libraries: 'places' // This is required if you use the Auto complete plug-in
  }
})

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// Feather font icon
require('./assets/css/iconfont.css')


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

// Vue CryptoJS
import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

// Vue Moment
import VueMoment from 'vue-moment'
Vue.use(VueMoment)

Vue.prototype.$secure = {
  encrypt (item) {
    return Vue.CryptoJS.AES.encrypt(item.toString(), "secretKey").toString()
  },

  decrypt (item) {
    return Vue.CryptoJS.AES.decrypt(item, "secretKey").toString(Vue.CryptoJS.enc.Utf8)
  }
}

Vue.config.productionTip = false

const vue = new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount('#app')

store.$vue = vue;
