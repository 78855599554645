/*
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
*/


import { getToken } from '@/axios'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [

    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/dashboard'
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('./views/DashboardAnalytics.vue'),
          meta: {
            pageTitle: 'Dashboard',
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Companies
        // =============================================================================
        {
          path: '/companies',
          name: 'companies',
          component: () => import('./views/companies/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/company/add',
          name: 'company-add',
          component: () => import('./views/companies/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Companies', url: '/companies' },
              { title: 'Add Company', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/company/:id',
          name: 'company-edit',
          component: () => import('./views/companies/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Companies', url: '/companies' },
              { title: 'Edit Company', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Users
        // =============================================================================
        {
          path: '/users',
          name: 'users',
          component: () => import('./views/users/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/user/add',
          name: 'user-add',
          component: () => import('./views/users/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Users', url: '/users' },
              { title: 'Add User', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/user/:id',
          name: 'user-edit',
          component: () => import('./views/users/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Users', url: '/users' },
              { title: 'Edit User', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Roles
        // =============================================================================
        {
          path: '/roles',
          name: 'roles',
          component: () => import('./views/roles/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/role/add',
          name: 'role-add',
          component: () => import('./views/roles/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Roles', url: '/roles' },
              { title: 'Add Role', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/role/:id',
          name: 'role-edit',
          component: () => import('./views/roles/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Roles', url: '/roles' },
              { title: 'Edit Role', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages City
        // =============================================================================
        {
          path: '/cities',
          name: 'city-index',
          component: () => import('./views/city/Index.vue'),
          meta: {
            pageTitle: 'Cities',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Cities', active: true },
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/city/add',
          name: 'city-add',
          component: () => import('./views/city/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Cities', url: '/cities' },
              { title: 'Add Bed', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/city/:id',
          name: 'city-edit',
          component: () => import('./views/city/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Cities', url: '/cities' },
              { title: 'Edit City', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Brand Admin
        // =============================================================================
        {
          path: '/brands',
          name: 'brands',
          component: () => import('./views/brands/admin/Index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Property', url: '/brands', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/brand/add',
          name: 'brand-add',
          component: () => import('./views/brands/admin/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Property', url: '/brands' },
              { title: 'Add Property', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/brand/:id',
          name: 'brand-edit',
          component: () => import('./views/brands/admin/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Property', url: '/brands' },
              { title: 'Edit Property', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Fine Print
        // =============================================================================
        {
          path: '/fine-prints',
          name: 'fine-print-index',
          component: () => import('./views/fine-print/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/fine-print/add',
          name: 'fine-print-add',
          component: () => import('./views/fine-print/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Fine Print', url: '/fine-print' },
              { title: 'Add Fine Print', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/fine-print/:id',
          name: 'fine-print-edit',
          component: () => import('./views/fine-print/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Fine Print', url: '/fine-print' },
              { title: 'Edit Fine Print', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Brand Tax
        // =============================================================================
        {
          path: '/brand-taxes',
          name: 'brand-taxes',
          component: () => import('./views/brand-tax/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/brand-taxes/add',
          name: 'brand-tax-add',
          component: () => import('./views/brand-tax/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Brand Taxes', url: '/brand-taxes' },
              { title: 'Add Brand Tax', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/brand-tax/:id',
          name: 'brand-tax-edit',
          component: () => import('./views/brand-tax/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Brand Taxes', url: '/brand-taxes' },
              { title: 'Edit Brand Tax', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Category Product
        // =============================================================================
        {
          path: '/category-products',
          name: 'category-product-index',
          component: () => import('./views/category-product/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/category-product/add',
          name: 'category-product-add',
          component: () => import('./views/category-product/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Category Products', url: '/category-products' },
              { title: 'Add Category Product', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/category-product/:id',
          name: 'category-product-edit',
          component: () => import('./views/category-product/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Category Products', url: '/category-products' },
              { title: 'Add Category Product', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Sub Category Product
        // =============================================================================
        {
          path: '/sub-category-products',
          name: 'sub-category-product-index',
          component: () => import('./views/sub-category-product/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/sub-category-product/add',
          name: 'sub-category-product-add',
          component: () => import('./views/sub-category-product/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Sub Category Products', url: '/sub-category-products' },
              { title: 'Add Sub Category Product', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/sub-category-product/:id',
          name: 'sub-category-product-edit',
          component: () => import('./views/sub-category-product/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Sub Category Products', url: '/sub-category-products' },
              { title: 'Add Sub Category Product', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Product Admin
        // =============================================================================
        {
          path: '/products',
          name: 'products',
          component: () => import('./views/products/admin/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/product/add',
          name: 'product-add',
          component: () => import('./views/products/admin/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Products', url: '/products' },
              { title: 'Add Product', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/product/:slug',
          name: 'product-edit',
          component: () => import('./views/products/admin/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Products', url: '/products' },
              { title: 'Edit Product', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Bed
        // =============================================================================
        {
          path: '/beds',
          name: 'beds',
          component: () => import('./views/beds/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/bed/add',
          name: 'bed-add',
          component: () => import('./views/beds/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Beds', url: '/beds' },
              { title: 'Add Bed', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/bed/:id',
          name: 'bed-edit',
          component: () => import('./views/beds/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Beds', url: '/beds' },
              { title: 'Edit Bed', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Promo Category
        // =============================================================================
        {
          path: '/promo-categories',
          name: 'promo-categories',
          component: () => import('./views/promo-category/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/promo-category/add',
          name: 'promo-category-add',
          component: () => import('./views/promo-category/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Promo Categories', url: '/promo-categories' },
              { title: 'Add Promo Category', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/promo-category/:id',
          name: 'promo-category-edit',
          component: () => import('./views/promo-category/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Promo Categories', url: '/promo-categories' },
              { title: 'Edit Promo Category', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Promo
        // =============================================================================
        {
          path: '/promos',
          name: 'promos',
          component: () => import('./views/promos/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/promo/add',
          name: 'promo-add',
          component: () => import('./views/promos/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Promo', url: '/promos' },
              { title: 'Add Promo', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/promo/:id',
          name: 'promo-edit',
          component: () => import('./views/promos/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Promo', url: '/promos' },
              { title: 'Edit Promo', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Partner
        // =============================================================================
        {
          path: '/company-content/partners',
          name: 'partners',
          component: () => import('./views/partners/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/company-content/partner/add',
          name: 'partner-add',
          component: () => import('./views/partners/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Partners', url: '/partners' },
              { title: 'Add Partner', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/company-content/partner/:id',
          name: 'partner-edit',
          component: () => import('./views/partners/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Partners', url: '/partners' },
              { title: 'Edit Partner', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Social Media
        // =============================================================================
        {
          path: '/company-content/social-media',
          name: 'social-media',
          component: () => import('./views/social-media/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/company-content/social-media/add',
          name: 'social-media-add',
          component: () => import('./views/social-media/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Social Media', url: '/social-media' },
              { title: 'Add Social Media', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/company-content/social-media/:id',
          name: 'social-media-edit',
          component: () => import('./views/social-media/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Social Media', url: '/social-media' },
              { title: 'Edit Social Media', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages FAQ
        // =============================================================================
        {
          path: '/company-content/faq',
          name: 'faqs',
          component: () => import('./views/faqs/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/company-content/faq/add',
          name: 'faq-add',
          component: () => import('./views/faqs/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'FAQS', url: '/company-content/faq' },
              { title: 'Add FAQ', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/company-content/faq/:slug',
          name: 'faq-edit',
          component: () => import('./views/faqs/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'FAQS', url: '/company-content/faq' },
              { title: 'Edit FAQ', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Feature Category
        // =============================================================================
        {
          path: '/feature-categories',
          name: 'feature-categories',
          component: () => import('./views/feature-category/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/feature-category/add',
          name: 'feature-category-add',
          component: () => import('./views/feature-category/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Feature Categories', url: '/feature-categories' },
              { title: 'Add Feature Category', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/feature-category/:id',
          name: 'feature-category-edit',
          component: () => import('./views/feature-category/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Feature Categories', url: '/feature-categories' },
              { title: 'Edit Feature Category', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Feature
        // =============================================================================
        {
          path: '/features',
          name: 'features',
          component: () => import('./views/features/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/feature/add',
          name: 'feature-add',
          component: () => import('./views/features/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Feature', url: '/features' },
              { title: 'Add Feature', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/feature/:id',
          name: 'feature-edit',
          component: () => import('./views/features/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Feature', url: '/features' },
              { title: 'Edit Feature', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Room Feature
        // =============================================================================
        {
          path: '/room-features',
          name: 'room-feature-index',
          component: () => import('./views/room-feature/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/room-feature/add',
          name: 'room-feature-add',
          component: () => import('./views/room-feature/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Room Feature', url: '/room-features' },
              { title: 'Add Room Feature', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/room-feature/:id',
          name: 'room-feature-edit',
          component: () => import('./views/room-feature/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Room Feature', url: '/room-features' },
              { title: 'Edit Room Feature', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Place Category
        // =============================================================================
        {
          path: '/place-categories',
          name: 'place-categories',
          component: () => import('./views/place-category/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/place-category/add',
          name: 'place-category-add',
          component: () => import('./views/place-category/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Place Categories', url: '/place-categories' },
              { title: 'Add Place Category', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/place-category/:id',
          name: 'place-category-edit',
          component: () => import('./views/place-category/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Place Categories', url: '/place-categories' },
              { title: 'Edit Place Category', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Place
        // =============================================================================
        {
          path: '/places',
          name: 'place-index',
          component: () => import('./views/place/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/place/add',
          name: 'place-add',
          component: () => import('./views/place/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Place', url: '/place' },
              { title: 'Add Place', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/place/:id',
          name: 'place-edit',
          component: () => import('./views/place/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Place', url: '/place' },
              { title: 'Edit Place', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Rate Plan Policy
        // =============================================================================
        {
          path: '/rate-plan-policies',
          name: 'rate-plan-policy-index',
          component: () => import('./views/rate-plan-policy/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/rate-plan-policy/add',
          name: 'rate-plan-policy-add',
          component: () => import('./views/rate-plan-policy/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Rate Plan Policy', url: '/rate-plan-policies' },
              { title: 'Add Rate Plan Policy', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/rate-plan-policy/:id',
          name: 'rate-plan-policy-edit',
          component: () => import('./views/rate-plan-policy/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Rate Plan Policy', url: '/rate-plan-policies' },
              { title: 'Edit Rate Plan Policy', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Meal
        // =============================================================================
        {
          path: '/meals',
          name: 'meal-index',
          component: () => import('./views/meal/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/meal/add',
          name: 'meal-add',
          component: () => import('./views/meal/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Meal', url: '/meals' },
              { title: 'Add Meal', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/meal/:id',
          name: 'meal-edit',
          component: () => import('./views/meal/Form.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Meal', url: '/meals' },
              { title: 'Edit Meal', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Property Promotion Category
        // =============================================================================
        {
          path: '/property-promotion-categories',
          name: 'property-promotion-categories',
          component: () => import('./views/property-promotion-category/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/property-promotion-category/add',
          name: 'property-promotion-category-add',
          component: () => import('./views/property-promotion-category/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Property Promotion Categories', url: '/property-promotion-categories' },
              { title: 'Add Property Promotion Category', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/property-promotion-category/:id',
          name: 'property-promotion-category-edit',
          component: () => import('./views/property-promotion-category/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Property Promotion Categories', url: '/property-promotion-categories' },
              { title: 'Edit Property Promotion Category', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Property Promotion
        // =============================================================================
        {
          path: '/property-promotions',
          name: 'property-promotion-index',
          component: () => import('./views/property-promotion/Index.vue'),
          meta: {
            pageTitle: 'Property Promotion',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Property Promotion', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/property-promotion/add',
          name: 'property-promotion-add',
          component: () => import('./views/property-promotion/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Property Promotions', url: '/property-promotions' },
              { title: 'Add Property Promotion', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/property-promotion/:id',
          name: 'property-promotion-edit',
          component: () => import('./views/property-promotion/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Property Promotions', url: '/property-promotions' },
              { title: 'Edit Property Promotion', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Payment Method
        // =============================================================================
        {
          path: '/payment-methods',
          name: 'payment-method-index',
          component: () => import('./views/payment-method/Index.vue'),
          meta: {
            pageTitle: 'Payment Method',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Payment Method', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/payment-method/add',
          name: 'payment-method-add',
          component: () => import('./views/payment-method/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Payment Methods', url: '/payment-methods' },
              { title: 'Add Payment Method', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/payment-method/:id',
          name: 'payment-method-edit',
          component: () => import('./views/payment-method/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Payment Methods', url: '/payment-methods' },
              { title: 'Edit Payment Method', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Payment Status
        // =============================================================================
        {
          path: '/payment-statuses',
          name: 'payment-status-index',
          component: () => import('./views/payment-status/Index.vue'),
          meta: {
            pageTitle: 'Payment Statuses',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Payment Statuses', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/payment-status/add',
          name: 'payment-status-add',
          component: () => import('./views/payment-status/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Payment Statuses', url: '/payment-statuses' },
              { title: 'Add Payment Status', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/payment-status/:id',
          name: 'payment-status-edit',
          component: () => import('./views/payment-status/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Payment Statuses', url: '/payment-statuses' },
              { title: 'Edit Payment Status', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Booking Channel
        // =============================================================================
        {
          path: '/booking-channels',
          name: 'booking-channel-index',
          component: () => import('./views/booking-channel/Index.vue'),
          meta: {
            pageTitle: 'Booking Channel',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Booking Channel', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/booking-channel/add',
          name: 'booking-channel-add',
          component: () => import('./views/booking-channel/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Booking Channels', url: '/booking-channels' },
              { title: 'Add Booking Channel', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/booking-channel/:id',
          name: 'booking-channel-edit',
          component: () => import('./views/booking-channel/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Booking Channels', url: '/booking-channels' },
              { title: 'Edit Booking Channel', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Reservation Status
        // =============================================================================
        {
          path: '/reservation-statuses',
          name: 'reservation-status-index',
          component: () => import('./views/reservation-status/Index.vue'),
          meta: {
            pageTitle: 'Reservation Status',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reservation Status', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/reservation-status/add',
          name: 'reservation-status-add',
          component: () => import('./views/reservation-status/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reservation Statuses', url: '/reservation-statuses' },
              { title: 'Add Reservation Status', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/reservation-status/:id',
          name: 'reservation-status-edit',
          component: () => import('./views/reservation-status/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reservation Statuses', url: '/reservation-statuses' },
              { title: 'Edit Reservation Status', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Menu Item Sidebar
        // =============================================================================
        {
          path: '/menu-items/sidebar',
          name: 'menu-item-sidebar',
          component: () => import('./views/menu-items/sidebar/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/menu-items/navbar',
          name: 'menu-item-navbar',
          component: () => import('./views/menu-items/navbar/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/menu-items/admin',
          name: 'menu-item-admin',
          component: () => import('./views/menu-items/admin/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/menu-item/add',
          name: 'menu-item-add',
          component: () => import('./views/menu-items/Add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Sidebar', url: '/menu-items/sidebar' },
              { title: 'Add Menu Item', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/menu-item/:id',
          name: 'menu-item-edit',
          component: () => import('./views/menu-items/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Sidebar', url: '/menu-items/sidebar' },
              { title: 'Edit Sidebar', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Routes
        // =============================================================================
        {
          path: '/user-settings',
          name: 'user-settings',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User Settings', active: true }
            ],
            pageTitle: 'User Settings',
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // VancyNet Pages
        // =============================================================================

        // =============================================================================
        // Property General Info
        // =============================================================================
        {
          path: '/vancynet/property/general-info',
          name: 'property-general-info',
          component: () => import('./views/vancynet/properties/GeneralInfo.vue'),
          meta: {
            pageTitle: 'General Info',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Properties', url: '/vancynet/properties'},
              { title: 'General Info', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Property VAT / Tax / Charge
        // =============================================================================
        {
          path: '/vancynet/property/tax',
          name: 'property-tax',
          component: () => import('./views/vancynet/properties/Tax.vue'),
          meta: {
            pageTitle: 'VAT / Tax / Charge',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Properties', active: true},
              { title: 'VAT / Tax / Charge', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Property Galleries
        // =============================================================================
        {
          path: '/vancynet/property/galleries',
          name: 'property-galleries',
          component: () => import('./views/vancynet/properties/Galleries.vue'),
          meta: {
            pageTitle: 'Galleries',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Properties', active: true},
              { title: 'Galleries', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Property Facilities
        // =============================================================================
        {
          path: '/vancynet/property/facilities',
          name: 'property-facilities',
          component: () => import('./views/vancynet/properties/Facilities.vue'),
          meta: {
            pageTitle: 'Facilities & Services',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Properties', active: true},
              { title: 'Facilities & Services', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Room Detail
        // =============================================================================
        {
          path: '/vancynet/room-detail',
          name: 'vancynet-room-detail',
          component: () => import('./views/vancynet/room-detail/Index.vue'),
          meta: {
            pageTitle: 'Room Detail',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Room Detail', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/vancynet/room-detail/add',
          name: 'vancynet-room-detail-add',
          component: () => import('./views/vancynet/room-detail/Add.vue'),
          meta: {
            pageTitle: 'Add Room',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Room Detail', url: '/vancynet/room-detail'},
              { title: 'Add Room', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/vancynet/room-detail/:id/edit',
          name: 'vancynet-room-detail-edit',
          component: () => import('./views/vancynet/room-detail/Edit.vue'),
          meta: {
            pageTitle: 'Edit Room Property',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Room Detail', url: '/vancynet/room-detail'},
              { title: 'Edit Room Property', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Room Amenities
        // =============================================================================
        {
          path: '/vancynet/room-amenities',
          name: 'vancynet-room-amenities',
          component: () => import('./views/vancynet/room-amenities/Index.vue'),
          meta: {
            pageTitle: 'Room Amenities',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Room Amenities', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Whats Nearby
        // =============================================================================
        {
          path: '/vancynet/brand-nearby',
          name: 'vancynet-brand-nearby',
          component: () => import('./views/vancynet/brand-nearby/Index.vue'),
          meta: {
            pageTitle: 'Brand Nearby',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Brand Nearby', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // View Description
        // =============================================================================
        {
          path: '/vancynet/view-description',
          name: 'vancynet-view-description',
          component: () => import('./views/vancynet/description/Index.vue'),
          meta: {
            pageTitle: 'View Description',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'View Description', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Rate Plan
        // =============================================================================
        {
          path: '/vancynet/rate-plans',
          name: 'vancynet-rate-plan-index',
          component: () => import('./views/vancynet/rate-plan/Index.vue'),
          meta: {
            pageTitle: 'Rate Plans',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Rate Plans', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/vancynet/rate-plan/add',
          name: 'vancynet-rate-plan-add',
          component: () => import('./views/vancynet/rate-plan/Add.vue'),
          meta: {
            pageTitle: 'Add Rate Plan',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Rate Plans', url: '/vancynet/rate-plans'},
              { title: 'Add Rate Plan', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/vancynet/rate-plan/:id/edit',
          name: 'vancynet-rate-plan-edit',
          component: () => import('./views/vancynet/rate-plan/Edit.vue'),
          meta: {
            pageTitle: 'Edit Rate Plan',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Rate Plans', url: '/vancynet/rate-plans'},
              { title: 'Edit Rate Plan', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Rate Plan Calendar
        // =============================================================================
        {
          path: '/vancynet/rate-plan/calendar',
          name: 'vancynet-rate-plan-calendar',
          component: () => import('./views/vancynet/rate-plan-calendar/Calendar.vue'),
          meta: {
            pageTitle: 'Rate Plan Schedule',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Rate Plan Schedule', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Promo Rate Plan
        // =============================================================================
        {
          path: '/vancynet/promo-rate-plans',
          name: 'vancynet-promo-rate-plan-index',
          component: () => import('./views/vancynet/promo-rate-plan/Index.vue'),
          meta: {
            pageTitle: 'Promo Rate Plans',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Promo Rate Plans', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/vancynet/promo-rate-plan/new',
          name: 'vancynet-new-promo-rate-plan',
          component: () => import('./views/vancynet/promo-rate-plan/New.vue'),
          meta: {
            pageTitle: 'New Promo Rate Plan',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'New Promo Rate Plan', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/vancynet/promo-rate-plan/add/:propertyPromotionID',
          name: 'vancynet-add-promo-rate-plan',
          component: () => import('./views/vancynet/promo-rate-plan/Add.vue'),
          meta: {
            pageTitle: 'Add Promo Rate Plan',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Add Promo Rate Plan', active: true},
            ],
            rule: 'editor',
            authRequired: true
          },
        },

        {
          path: '/vancynet/promo-rate-plan/:id/edit',
          name: 'vancynet-edit-promo-rate-plan',
          component: () => import('./views/vancynet/promo-rate-plan/Edit.vue'),
          meta: {
            pageTitle: 'Edit Promo Rate Plan',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Edit Promo Rate Plan', active: true},
            ],
            rule: 'editor',
            authRequired: true
          },
        },

        // =============================================================================
        // Reservation
        // =============================================================================
        {
          path: '/vancynet/reservations',
          name: 'vancynet-reservation-index',
          component: () => import('./views/vancynet/reservation/Index.vue'),
          meta: {
            pageTitle: 'Reservations',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reservations', active: true},
            ],
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/vancynet/reservation/:bookingNumber',
          name: 'vancynet-reservation-show',
          component: () => import('./views/vancynet/reservation/Show.vue'),
          meta: {
            pageTitle: 'Reservation Detail',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reservations', url: '/vancynet/reservations'},
              { title: 'Reservation Detail', active: true}
            ],
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Bus Station
        // =============================================================================
        {
          path: '/bus-station',
          name: 'bus-station',
          component: () => import('./views/bus-station/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/bus-station/add',
          name: 'create-bus-station',
          component: () => import('./views/bus-station/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/bus-station/:id',
          name: 'bus-edit',
          component: () => import('./views/bus-station/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Pages Seat Arrangement
        // =============================================================================
        {
          path: '/seat-arrangement',
          name: 'seat-arrangement',
          component: () => import('./views/seat-arrangement/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/seat-arrangement/add',
          name: 'create-seat-arrangement',
          component: () => import('./views/seat-arrangement/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/seat-arrangement/:id',
          name: 'seat-edit',
          component: () => import('./views/seat-arrangement/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Pages Brand Testimony
        // =============================================================================
        {
          path: '/brand-testimony',
          name: 'brand-testimony',
          component: () => import('./views/brand-testimony/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/brand-testimony/add',
          name: 'create-brand-testimony',
          component: () => import('./views/brand-testimony/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/brand-testimony/:id',
          name: 'brand-testimony-edit',
          component: () => import('./views/brand-testimony/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Pages Sitemap
        // =============================================================================
        {
          path: '/sitemap',
          name: 'sitemap',
          component: () => import('./views/sitemap/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/sitemap/add',
          name: 'create-sitemap',
          component: () => import('./views/sitemap/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/sitemap/:id',
          name: 'sitemap-edit',
          component: () => import('./views/sitemap/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Pages Why choose us
        // =============================================================================
        {
          path: '/why-choose-us',
          name: 'why-choose-us',
          component: () => import('./views/why-choose-us/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/why-choose-us/add',
          name: 'create-why-choose-us',
          component: () => import('./views/why-choose-us/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/why-choose-us/:id',
          name: 'why-choose-us-edit',
          component: () => import('./views/why-choose-us/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Pages Fleet Type
        // =============================================================================
        {
          path: '/fleet-type',
          name: 'fleet-type',
          component: () => import('./views/fleet-type/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/fleet-type/add',
          name: 'create-fleet-type',
          component: () => import('./views/fleet-type/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/fleet-type/:id',
          name: 'fleet-type-edit',
          component: () => import('./views/fleet-type/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Pages Identification Type
        // =============================================================================
        {
          path: '/identification-type',
          name: 'identification-type',
          component: () => import('./views/identification-type/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/identification-type/add',
          name: 'create-identification-type',
          component: () => import('./views/identification-type/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/identification-type/:id',
          name: 'identification-type-edit',
          component: () => import('./views/identification-type/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Pages Product Bus
        // =============================================================================
        {
          path: '/bus-product',
          name: 'bus-product',
          component: () => import('./views/bus-product/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/bus-product/add',
          name: 'create-bus-product',
          component: () => import('./views/bus-product/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/bus-product/:id',
          name: 'bus-product-edit',
          component: () => import('./views/bus-product/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Pages Bus Feature
        // =============================================================================
        {
          path: '/bus-feature',
          name: 'bus-feature',
          component: () => import('./views/bus-feature/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/bus-feature/add',
          name: 'create-bus-feature',
          component: () => import('./views/bus-feature/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/bus-feature/:id',
          name: 'bus-feature-edit',
          component: () => import('./views/bus-feature/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Pages Airport
        // =============================================================================
        {
          path: '/airport',
          name: 'airport',
          component: () => import('./views/airport/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/airport/add',
          name: 'create-airport',
          component: () => import('./views/airport/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/airport/:id',
          name: 'airport-edit',
          component: () => import('./views/airport/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Food Merchant Category
        // =============================================================================
        {
          path: '/food-merchant-category',
          name: 'food-merchant-category',
          component: () => import('./views/food-merchant-category/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/food-merchant-category/add',
          name: 'create-food-merchant-category',
          component: () => import('./views/food-merchant-category/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/food-merchant-category/:id',
          name: 'food-merchant-category-edit',
          component: () => import('./views/food-merchant-category/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Food Merchant
        // =============================================================================
        {
          path: '/food-merchant',
          name: 'food-merchant',
          component: () => import('./views/food-merchant/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/food-merchant/add',
          name: 'create-food-merchant',
          component: () => import('./views/food-merchant/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/food-merchant/:id',
          name: 'food-merchant-edit',
          component: () => import('./views/food-merchant/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Food Merchant Brand
        // =============================================================================
        {
          path: '/food-merchant-branch',
          name: 'food-merchant-branch',
          component: () => import('./views/food-merchant-branch/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/food-merchant-branch/add',
          name: 'create-food-merchant-branch',
          component: () => import('./views/food-merchant-branch/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/food-merchant-branch/:id',
          name: 'food-merchant-branch-edit',
          component: () => import('./views/food-merchant-branch/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Food Voucher
        // =============================================================================
        {
          path: '/food-voucher',
          name: 'food-voucher',
          component: () => import('./views/food-voucher/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/food-voucher/add',
          name: 'create-food-voucher',
          component: () => import('./views/food-voucher/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/food-voucher/:id',
          name: 'food-voucher-edit',
          component: () => import('./views/food-voucher/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Food Voucher branch
        // =============================================================================
        {
          path: '/food-voucher-review',
          name: 'food-voucher-review',
          component: () => import('./views/food-voucher-review/Index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/food-voucher-review/add',
          name: 'create-food-voucher-review',
          component: () => import('./views/food-voucher-review/add.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/food-voucher-review/:id',
          name: 'food-voucher-review-edit',
          component: () => import('./views/food-voucher-review/Edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/auth/login',
          name: 'page-login',
          component: () => import('@/views/auth/login/Login.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/auth/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/auth/ForgotPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/auth/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/auth/ResetPassword.vue'),
          meta: {
            rule: 'editor',
          }
        },
        {
          path: '/pages/error-403',
          name: 'page-error-403',
          component: () => import('@/views/pages/Error403.vue'),
          meta: {
            rule: 'editor',
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'editor',
          }
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
          }
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'editor'
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
    const userRole = localStorage.getItem('userInfo')
                      ? JSON.parse(localStorage.getItem('userInfo')).userInfo.role
                      : false

    // If auth required, check login. If login fails redirect to login page
    if (to.meta.authRequired) {
      if (!getToken()) {
        router.push({ path: '/auth/login' })
      }
    }

    if (to.meta.roles && !to.meta.roles.includes(userRole.name)) {
      router.push({name: 'page-not-authorized'})
    }

    return next()
})

export default router
