import jwt from '../../http/requests/auth/jwt/index.js'

import router from '@/router'

const clearStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userInfo');
  localStorage.removeItem('refreshToken');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userInfo');
  sessionStorage.removeItem('refreshToken');
}
export default {
  updateUsername ({ commit }, payload) {
    payload.user.updateProfile({
      displayName: payload.displayName
    }).then(() => {

      // If username update is success
      // update in localstorage
      const newUserData = Object.assign({}, payload.user.providerData[0])
      newUserData.displayName = payload.displayName
      commit('UPDATE_USER_INFO', newUserData, {root: true})

      // If reload is required to get fresh data after update
      // Reload current page
      if (payload.isReloadRequired) {
        router.push(router.currentRoute.query.to || '/')
      }
    }).catch((err) => {
      payload.notify({
        time: 8800,
        title: 'Error',
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    })
  },
  loginJWT ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      jwt.login(payload.userDetails.email, payload.userDetails.password)
        .then(response => {
          // If there's user data in response
          console.log(response)
          if (response.data) {
            if (!['tenant', 'admin'].includes(response.data.role)) {
              reject({message: 'User not allowed to login'})
              return
            }
            // Set accessToken

            if (payload.remember_me) {
              localStorage.setItem('token', response.data.accessToken)
              localStorage.setItem('refreshToken', response.data.refreshToken)
            } else {
              sessionStorage.setItem('token', response.data.accessToken)
              sessionStorage.setItem('refreshToken', response.data.refreshToken)
            }

            // Update user details
            commit('UPDATE_USER_INFO', response.data, {root: true})

            // Set bearer token in axios
            commit('SET_BEARER', response.data.accessToken)
            const redirectUrl = localStorage.getItem('redirectUrl') ?? '/';

            // Navigate User to homepage
            router.push(redirectUrl)
            localStorage.removeItem('redirectUrl')

            resolve(response)
          } else {
            reject({message: response.data.message})
          }

        })
        .catch(error => { reject({message: error.data.message}) })
    })
  },
  registerUserJWT ({ commit }, payload) {

    const { displayName, email, password, confirmPassword } = payload.userDetails

    return new Promise((resolve, reject) => {

      // Check confirm password
      if (password !== confirmPassword) {
        reject({message: 'Password doesn\'t match. Please try again.'})
      }

      jwt.registerUser(displayName, email, password)
        .then(response => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/')

          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.accessToken)
          commit('UPDATE_USER_INFO', response.data, {root: true})

          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  fetchAccessToken () {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => { resolve(response) })
    })
  },
  clearStorage,
  async logout({ commit }) {
    clearStorage();
    commit('UPDATE_IS_FETCHED', false);
    await router.push({ path: "/auth/login" });
  },
}
