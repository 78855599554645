export const sidebar = [
  {
    url: "/dashboard",
    name: "Dashboard",
    icon: "HomeIcon",
    slug: "dashboard",
    i18n: "Dashboard",
    submenu: null
  },
  {
    url: "#",
    name: "Property",
    icon: "BriefcaseIcon",
    slug: "property",
    i18n: "Property",
    submenu: [
      {
        id: 21,
        slug: "general-info",
        name: "General Info",
        url: "/vancynet/property/general-info",
        icon: "InfoIcon"
      },
      {
        id: 22,
        slug: "tax",
        name: "Tax",
        url: "/vancynet/property/tax",
        icon: "DollarSignIcon"
      },
      {
        id: 24,
        slug: "facilities",
        name: "Facilities",
        url: "/vancynet/property/facilities",
        icon: "AwardIcon"
      },
      {
        id: 26,
        slug: "room-detail",
        name: "Room Detail",
        url: "/vancynet/room-detail",
        icon: "MoreHorizontalIcon"
      },
      {
        id: 27,
        slug: "room-amenities",
        name: "Room Amenities",
        url: "/vancynet/room-amenities",
        icon: "MoreHorizontalIcon"
      },
      {
        id: 23,
        slug: "galleries",
        name: "Galleries",
        url: "/vancynet/property/galleries",
        icon: "ImageIcon"
      },
      // {
      //   id: 28,
      //   slug: "brand-nearby",
      //   name: "Brand Nearby",
      //   url: "/vancynet/brand-nearby",
      //   icon: "MapPinIcon"
      // },
      // {
      //   id: 36,
      //   slug: "view-description",
      //   name: "View Description",
      //   url: "/vancynet/view-description",
      //   icon: "EyeIcon"
      // }
    ]
  },
  {
    url: "#",
    name: "Rate Plan Menus",
    icon: "ActivityIcon",
    slug: "rate-plan-menus",
    i18n: "RatePlanMenus",
    submenu: [
      {
        id: 44,
        slug: "rate-plan",
        name: "Rate Plan",
        url: "/vancynet/rate-plans",
        icon: "#"
      },
      {
        id: 45,
        slug: "calendar",
        name: "Calendar",
        url: "/vancynet/rate-plan/calendar",
        icon: "#"
      }
    ]
  },
  // {
  //   url: "#",
  //   name: "Promotion",
  //   icon: "PercentIcon",
  //   slug: "promotion",
  //   i18n: "Promotion",
  //   submenu: [
  //     {
  //       id: 43,
  //       slug: "promo-rate-plan",
  //       name: "Promo Rate Plan",
  //       url: "/vancynet/promo-rate-plans",
  //       icon: "PercentIcon"
  //     },
  //     {
  //       url: "/property-promotions",
  //       name: "Property Promotion",
  //       icon: "PercentIcon",
  //       slug: "property-promotion",
  //       i18n: "PropertyPromotion",
  //       submenu: null
  //     },
  //   ]
  // },
  {
    url: "/vancynet/reservations",
    name: "Reservation",
    icon: "BookOpenIcon",
    slug: "reservation",
    i18n: "Reservations",
    submenu: null
  },
  // {
  //   url: "/finances",
  //   name: "Finances",
  //   icon: "BookIcon",
  //   slug: "finances",
  //   i18n: "Finances",
  //   submenu: null
  // },
];
