import axios from '@/axios'

export default {
  async getRoomList({ commit }, { hotelId, vm }) {
    try {
      const { data } = await axios.get(`/hotels/${hotelId}/rooms`);
      if (data.response_code === 0) {
        return data.data.rooms;
      }
    } catch (err) {
      this.$vue.$catchErrorResponse(err)
      return []
    }
  },
  findPropertyMainPhoto({}, room) {
    const mainPhoto = room.images.find(image => image.isMain) ??
      { url: 'https://dummyimage.com/600x400/e6e6e6/3b3b3b&text=Main+photo+is+not+available'};
    return mainPhoto.url;
  },
}
