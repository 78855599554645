export const sidebar = [
  {
    url: "/dashboard",
    name: "Dashboard",
    icon: "HomeIcon",
    slug: "dashboard",
    i18n: "Dashboard",
    submenu: null
  },
  {
    url: "#",
    name: "Data Master",
    icon: "BoxIcon",
    slug: "data-master",
    i18n: "DataMaster",
    submenu: [
      {
        id: 6,
        slug: "brand",
        name: "Property",
        url: "/brands",
        icon: "BoxIcon"
      },
      {
        id: 30,
        slug: "category-product",
        name: "Category Product",
        url: "/category-products",
        icon: "BoxIcon"
      },
      {
        id: 50,
        slug: "city",
        name: "City",
        url: "/cities",
        icon: "GlobeIcon"
      },
      {
        id: 10,
        slug: "feature-category",
        name: "Feature",
        url: "/feature-categories",
        icon: "CircleIcon",
        submenu: [
          {
            id: 99,
            slug: "feature-category",
            name: "Feature Category",
            url: "/feature-categories",
            icon: "GlobeIcon",
          },
          {
            id: 11,
            slug: "feature",
            name: "Feature (Hotel facilities)",
            url: "/features",
            icon: "StarIcon"
          },
        ]
      },
      {
        id: 39,
        slug: "meal",
        name: "Meal",
        url: "/meals",
        icon: "ClipboardIcon"
      },
      // {
      //   id: 46,
      //   slug: "payment-method",
      //   name: "Payment Method",
      //   url: "/payment-methods",
      //   icon: "DollarSignIcon"
      // },
      // {
      //   id: 51,
      //   slug: "payment-status",
      //   name: "Payment Status",
      //   url: "/payment-statuses",
      //   icon: "DollarSignIcon"
      // },
      // {
      //   id: 100,
      //   url: '#',
      //   name: "Places",
      //   slug: "feature-category",
      //   icon: "CircleIcon",
      //   submenu: [
      //     // {
      //     //   id: 32,
      //     //   slug: "place-category",
      //     //   name: "Place Category",
      //     //   url: "/place-categories",
      //     //   icon: "BoxIcon"
      //     // },
      //     // {
      //     //   id: 33,
      //     //   slug: "place",
      //     //   name: "Place (Nearby)",
      //     //   url: "/places",
      //     //   icon: "HomeIcon"
      //     // },
      //   ]
      // },
      // {
      //   id: 35,
      //   slug: "rate-plan-policy",
      //   name: "Rate Plan Policy",
      //   url: "/rate-plan-policies",
      //   icon: "AlertOctagonIcon"
      // },
      // {
      //   id: 47,
      //   slug: "reservation-status",
      //   name: "Reservation Status",
      //   url: "/reservation-statuses",
      //   icon: "ShoppingCartIcon"
      // },
      // {
      //   id: 31,
      //   slug: "sub-category-product",
      //   name: "Sub Category Product",
      //   url: "/sub-category-products",
      //   icon: "BoxIcon"
      // }
    ]
  },
  {
    url: "#",
    name: "Accomodation",
    icon: "InboxIcon",
    slug: "accomodation",
    i18n: "Accomodation",
    submenu: [
      {
        id: 25,
        slug: "bed",
        name: "Bed",
        url: "/beds",
        icon: "InboxIcon"
      },
      {
        id: 29,
        slug: "brand-tax",
        name: "Tax & Service + Commissions",
        url: "/brand-taxes",
        icon: "DollarSignIcon"
      },
      {
        id: 40,
        slug: "room-feature",
        name: "Room Feature (Amenities)",
        url: "/room-features",
        icon: "StarIcon"
      },
      // {
      //   id: 48,
      //   slug: "booking-channel",
      //   name: "Booking Channel",
      //   url: "/booking-channels",
      //   icon: "WifiIcon"
      // },
      // {
      //   id: 34,
      //   slug: "fine-print",
      //   name: "Fine Print",
      //   url: "/fine-prints",
      //   icon: "ToolIcon"
      // },
    ]
  },
  // {
  //   url: "#",
  //   name: "Promotion",
  //   icon: "PercentIcon",
  //   slug: "promotion",
  //   i18n: "Promotion",
  //   submenu: [
  //     {
  //       id: 8,
  //       slug: "promo-category",
  //       name: "Promo Category",
  //       url: "/promo-categories",
  //       icon: "BoxIcon"
  //     },
  //     {
  //       id: 38,
  //       slug: "property-promotion-category",
  //       name: "Property Promotion Category",
  //       url: "/property-promotion-categories",
  //       icon: "BoxIcon"
  //     },
  //     {
  //       url: "/promos",
  //       name: "Promo",
  //       icon: "PercentIcon",
  //       slug: "promo",
  //       i18n: "Promo",
  //       submenu: null
  //     },
  //     {
  //       id: 43,
  //       slug: "promo-rate-plan",
  //       name: "Rate Plan Promo",
  //       url: "/vancynet/promo-rate-plans",
  //       icon: "PercentIcon"
  //     },
  //   ]
  // },
  // {
  //   url: "#",
  //   name: "Company Content",
  //   icon: "BookOpenIcon",
  //   slug: "company-content",
  //   i18n: "CompanyContent",
  //   submenu: [
  //     {
  //       id: 13,
  //       slug: "partner",
  //       name: "Partner",
  //       url: "/company-content/partners",
  //       icon: "-"
  //     },
  //     {
  //       id: 14,
  //       slug: "social-media",
  //       name: "Social Media",
  //       url: "/company-content/social-media",
  //       icon: "-"
  //     },
  //     {
  //       id: 15,
  //       slug: "faq",
  //       name: "FAQ",
  //       url: "/company-content/faq",
  //       icon: "-"
  //     }
  //   ]
  // },
  {
    url: "/vancynet/reservations",
    name: "Reservation",
    icon: "BookOpenIcon",
    slug: "reservation",
    i18n: "Reservations",
    submenu: null
  },
  // {
  //   url: "/products",
  //   name: "Product",
  //   icon: "BoxIcon",
  //   slug: "product",
  //   i18n: "Product",
  //   submenu: null
  // },
  // {
  //   url: "/finances",
  //   name: "Finances",
  //   icon: "BookIcon",
  //   slug: "finances",
  //   i18n: "Finances",
  //   submenu: null
  // },
  {
    url: "#",
    name: "Settings",
    icon: "SettingsIcon",
    slug: "settings",
    i18n: "Settings",
    submenu: [
      {
        id: 2,
        slug: "company",
        name: "Company",
        url: "/companies",
        icon: "HomeIcon"
      },
      {
        id: 3,
        slug: "role",
        name: "Role",
        url: "/roles",
        icon: "KeyIcon"
      },
      {
        id: 4,
        slug: "user",
        name: "User",
        url: "/users",
        icon: "UsersIcon"
      },
      {
        id: 5,
        slug: "user-setting",
        name: "User Setting",
        url: "/user-settings",
        icon: "SettingsIcon"
      },
      // {
      //   id: 19,
      //   slug: "admin-menu-item",
      //   name: "Admin Menu Item",
      //   url: "/menu-items/admin",
      //   icon: "-"
      // },
      // {
      //   id: 18,
      //   slug: "navbar-menu-item",
      //   name: "Navbar Menu Item",
      //   url: "/menu-items/navbar",
      //   icon: "-"
      // },
      // {
      //   id: 17,
      //   slug: "sidebar-menu-item",
      //   name: "Sidebar Menu Item",
      //   url: "/menu-items/sidebar",
      //   icon: "-"
      // }
    ]
  },
  // {
  //   url: "/identification-type",
  //   name: "Identification Type",
  //   icon: "CreditCardIcon",
  //   slug: "identification-type",
  //   i18n: "IdentificationType",
  //   submenu: null
  // }
];
